import React from 'react';
import './CoreValueCard.css'; // Import CSS file for styling
import { BsAward, BsLightbulb, BsPersonCheck, BsPeople, BsBalloonHeart, BsChatHeart } from "react-icons/bs";
const CoreValueCard = ({ title, items, icon }) => {
  return (
    <div className="core-value-card">
      <div className='core-value-card-title'>
        <BsAward className='icon' size='30%' style={{display: icon === 'BsAwardFill' ? 'block' : 'none'}}/>
        <BsLightbulb className='icon' size='30%' style={{display: icon === 'BsFillLightbulbFill' ? 'block' : 'none' }}/>
        <BsPersonCheck className='icon' size='30%' style={{display: icon === 'BsFillPersonCheckFill' ? 'block' : 'none' }}/>
        <BsPeople className='icon' size='30%' style={{display: icon === 'BsPeopleFill' ? 'block' : 'none' }}/>
        <BsBalloonHeart className='icon' size='30%' style={{display: icon === 'BsFillBalloonHeartFill' ? 'block' : 'none' }}/>
        <BsChatHeart className='icon' size='30%' style={{display: icon === 'BsChatHeartFill' ? 'block' : 'none' }}/>
        </div>
      <h3>{title}</h3>
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default CoreValueCard;